:root {
	--main-bg-color: #250d47;
	--text-color: #6826c7;
	--nav-bg-color: #16082a;
	--nav-hover-color: #9661e1;
	--nav-selected-color: #521e9c;
	--nav-selected-text-color: #9661e1;
	--flicker-color: #d8c5f4;
	--flicker-shadow: 2px 2px 10px var(--flicker-color);
}

html {
	font-family: 'Aldrich', sans-serif;
	font-size: 1rem;
}

body {
	margin: 0;
	display: flex;
	flex-direction: column;
	background-color: var(--main-bg-color);
	color: var(--text-color);
	position: relative;
}

.btn-show-months {
	border: none;
	background: #341363;
	background: var(--main-bg-color);
	padding: 0.5rem;
	color: var(--nav-selected-text-color);
	font-size: 2rem;
	cursor: pointer;
	z-index: 1;

	@media (min-width: 40rem) {
		background: var(--nav-bg-color);
		width: 5rem;
	}
}

.btn-show-months-open {
	background-color: var(--nav-bg-color);
	color: var(--nav-selected-text-color);
}

nav {
	view-transition-name: month-nav;
	background-color: var(--nav-bg-color);
	position: fixed;
	top: 3rem;
	width: 100vw;
	height: 100vh;

	transition: height;

	> :first-child {
		padding-top: 1rem;
	}

	li {
		list-style: none;
		font-size: 10vw;
		text-align: center;

		@media (min-width: 40rem) {
			font-size: 5vw;
		}

		@media (min-width: 75rem) {
			font-size: 3vw;
		}

		button {
			background-color: var(--nav-bg-color);
			color: var(--text-color);
			border: none;
			cursor: pointer;
			padding: 0.5rem 0.5rem 0.2rem;
			border-radius: 2px;
			text-transform: uppercase;

			&:hover {
				background-color: var(--nav-hover-color);
			}

			&.month-selected {
				background-color: var(--nav-selected-color);
				color: var(--nav-selected-text-color);
			}
		}
	}
}

main {
	display: flex;
	justify-content: center;
	margin-top: auto;
	margin-bottom: auto;
	color: var(--main-bg-color);
	font-family: 'Moirai One', system-ui;

	.hours {
		text-shadow: none;
		span {
			font-size: 50vw;
			line-height: 50vh;
		}
	}

	span:first-child {
		animation: 3s 0s infinite flickering;
	}
	span:nth-child(2) {
		animation: 3s 0s infinite flickering;
		animation-delay: 0.7s;
	}
	span:nth-child(3) {
		animation: 3s 0s infinite reverse flickering;
		animation-delay: 0.5s;
	}
}

footer {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin-top: auto;
}

@keyframes flickering {
	0% {
		color: var(--main-bg-color);
		text-shadow: none;
	}
	5% {
		color: var(--flicker-color);
		text-shadow: var(--flicker-shadow);
	}
	9% {
		color: var(--main-bg-color);
		text-shadow: none;
	}
	49% {
		color: var(--main-bg-color);
		text-shadow: none;
	}
	50% {
		color: var(--flicker-color);
		text-shadow: var(--flicker-shadow);
	}
	53% {
		color: var(--main-bg-color);
		text-shadow: none;
	}
	59% {
		color: var(--flicker-color);
		text-shadow: var(--flicker-shadow);
	}
	62% {
		color: var(--main-bg-color);
		text-shadow: none;
	}

	80% {
		color: var(--main-bg-color);
		text-shadow: none;
	}
	85% {
		color: var(--flicker-color);
		text-shadow: var(--flicker-shadow);
	}
	88% {
		color: var(--main-bg-color);
		text-shadow: none;
	}
	90% {
		color: var(--flicker-color);
		text-shadow: var(--flicker-shadow);
	}
	94% {
		color: var(--main-bg-color);
		text-shadow: none;
	}
	100% {
		color: var(--main-bg-color);
		text-shadow: none;
	}
}

@keyframes slide-from-right {
	from {
		opacity: 1;
		transform: translateX(-100%);
	}
}

@keyframes slide-to-left {
	to {
		opacity: 1;
		transform: translateX(-100%);
	}
}

@keyframes slide-from-top {
	from {
		opacity: 1;
		transform: translateY(100%);
	}
}

@keyframes slide-to-top {
	to {
		opacity: 1;
		transform: translateY(100%);
	}
}

::view-transition-old(month-nav) {
	animation: 350ms both slide-to-top ease;

	@media (min-width: 40rem) {
		animation: 500ms both slide-to-left ease;
	}
}

::view-transition-new(month-nav) {
	animation: 350ms both slide-from-top ease;

	@media (min-width: 40rem) {
		animation: 500ms both slide-from-right ease;
	}
}
