:root {
  --main-bg-color: #250d47;
  --text-color: #6826c7;
  --nav-bg-color: #16082a;
  --nav-hover-color: #9661e1;
  --nav-selected-color: #521e9c;
  --nav-selected-text-color: #9661e1;
  --flicker-color: #d8c5f4;
  --flicker-shadow: 2px 2px 10px var(--flicker-color);
}

html {
  font-family: Aldrich, sans-serif;
  font-size: 1rem;
}

body {
  background-color: var(--main-bg-color);
  color: var(--text-color);
  flex-direction: column;
  margin: 0;
  display: flex;
  position: relative;
}

.btn-show-months {
  background: #341363;
  background: var(--main-bg-color);
  color: var(--nav-selected-text-color);
  cursor: pointer;
  z-index: 1;
  border: none;
  padding: .5rem;
  font-size: 2rem;
}

@media (width >= 40rem) {
  .btn-show-months {
    background: var(--nav-bg-color);
    width: 5rem;
  }
}

.btn-show-months-open {
  background-color: var(--nav-bg-color);
  color: var(--nav-selected-text-color);
}

nav {
  view-transition-name: month-nav;
  background-color: var(--nav-bg-color);
  width: 100vw;
  height: 100vh;
  transition: height;
  position: fixed;
  top: 3rem;
}

nav > :first-child {
  padding-top: 1rem;
}

nav li {
  text-align: center;
  font-size: 10vw;
  list-style: none;
}

@media (width >= 40rem) {
  nav li {
    font-size: 5vw;
  }
}

@media (width >= 75rem) {
  nav li {
    font-size: 3vw;
  }
}

nav li button {
  background-color: var(--nav-bg-color);
  color: var(--text-color);
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  border-radius: 2px;
  padding: .5rem .5rem .2rem;
}

nav li button:hover {
  background-color: var(--nav-hover-color);
}

nav li button.month-selected {
  background-color: var(--nav-selected-color);
  color: var(--nav-selected-text-color);
}

main {
  color: var(--main-bg-color);
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  font-family: Moirai One, system-ui;
  display: flex;
}

main .hours {
  text-shadow: none;
}

main .hours span {
  font-size: 50vw;
  line-height: 50vh;
}

main span:first-child {
  animation: 3s infinite flickering;
}

main span:nth-child(2) {
  animation: 3s .7s infinite flickering;
}

main span:nth-child(3) {
  animation: 3s .5s infinite reverse flickering;
}

footer {
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: auto;
  display: flex;
}

@keyframes flickering {
  0% {
    color: var(--main-bg-color);
    text-shadow: none;
  }

  5% {
    color: var(--flicker-color);
    text-shadow: var(--flicker-shadow);
  }

  9% {
    color: var(--main-bg-color);
    text-shadow: none;
  }

  49% {
    color: var(--main-bg-color);
    text-shadow: none;
  }

  50% {
    color: var(--flicker-color);
    text-shadow: var(--flicker-shadow);
  }

  53% {
    color: var(--main-bg-color);
    text-shadow: none;
  }

  59% {
    color: var(--flicker-color);
    text-shadow: var(--flicker-shadow);
  }

  62% {
    color: var(--main-bg-color);
    text-shadow: none;
  }

  80% {
    color: var(--main-bg-color);
    text-shadow: none;
  }

  85% {
    color: var(--flicker-color);
    text-shadow: var(--flicker-shadow);
  }

  88% {
    color: var(--main-bg-color);
    text-shadow: none;
  }

  90% {
    color: var(--flicker-color);
    text-shadow: var(--flicker-shadow);
  }

  94% {
    color: var(--main-bg-color);
    text-shadow: none;
  }

  100% {
    color: var(--main-bg-color);
    text-shadow: none;
  }
}

@keyframes slide-from-right {
  from {
    opacity: 1;
    transform: translateX(-100%);
  }
}

@keyframes slide-to-left {
  to {
    opacity: 1;
    transform: translateX(-100%);
  }
}

@keyframes slide-from-top {
  from {
    opacity: 1;
    transform: translateY(100%);
  }
}

@keyframes slide-to-top {
  to {
    opacity: 1;
    transform: translateY(100%);
  }
}

::view-transition-old(month-nav) {
  animation: .35s both slide-to-top;
}

@media (width >= 40rem) {
  ::view-transition-old(month-nav) {
    animation: .5s both slide-to-left;
  }
}

::view-transition-new(month-nav) {
  animation: .35s both slide-from-top;
}

@media (width >= 40rem) {
  ::view-transition-new(month-nav) {
    animation: .5s both slide-from-right;
  }
}

/*# sourceMappingURL=index.125e4305.css.map */
